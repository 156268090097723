<template>
  <div 
    :class="{
      'mr-2': !altView,
    }"
  >
    <VBtn
      v-if="altView"
      height="50px"
      width="50px"
      min-width="50px"
      style="border: 1px solid #DEDEDE; border-radius: 10px;"
      :color="getColor"
      @click="toggleEmojiPicker"
    >
      <span 
        v-if="getAvatarContent.trim().length > 0" 
        style="font-size: 22px;"
        :class="{
          'white--text': getColor != '#ffffff',
          'black--text': getColor == '#ffffff',
        }"
      >
        {{ getAvatarContent }}
      </span>
      <VIcon 
        v-else
        color="#000000"
      >
      mdi-plus
      </VIcon>
    </VBtn>
    <template v-else>
      <VAvatar
        v-if="hasEmoji"
        size="40"
        style="cursor: pointer;"
        :color="getColor"
        @click="toggleEmojiPicker"
      >
        <span
          class="white--text"
          style="font-size: 18px;"
        >
          {{ getAvatarContent }}
        </span>
      </VAvatar>
      <VBadge
        v-else
        overlap
        bordered
        color="#ebebeb"
      >
        <template #badge>
          <VIcon
            color="#6c6c6c"
            size="18px"
          >
            mdi-plus
          </VIcon>
        </template>
        <VAvatar
          size="40"
          color="#ebebeb"
          style="cursor: pointer;"
          @click="toggleEmojiPicker"
        >
          <VIcon>mdi-emoticon-outline</VIcon>
        </VAvatar>
      </VBadge>
    </template>
    <EmojiPicker
      enable-color-picker
      ref="emojiPicker"
      :color="getColor"
      @change="onChangeEmoji"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    altView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: null,
      text: null,
      emoji: null,
    }
  },
  computed: {
    getColor() {
      if(this.color) {
        return this.color;
      } else if(this.item) {
        if(this.altView) {
          return this.item.background_color || "#FFC100";
        }
        return this.item.background_color || "#ebebeb";
      } else {
        if(this.altView) {
          return "#FFC100";
        }
        return "#ebebeb";
      }
    },
    getAvatarContent() {
      if(this.emoji) {
        return this.emoji;
      } else if(this.item) {
        return this.item.emoji || this.item.title.replace("[Copy]", "").trim().charAt(0).capitalize();
      } else {
        return this.text;
      }
    },
    hasEmoji() {
      if(this.color || this.emoji) {
        return true;
      }
      if(this.item) {
        return this.item.emoji || this.item.background_color;
      }
      return false;
    }
  },
  methods: {
    toggleEmojiPicker() {
      this.$refs.emojiPicker.togglePicker();
    },
    onChangeEmoji(emoji, color) {
      this.emoji = emoji || this.item.emoji;
      this.color = color;
      this.$emit("change", this.emoji, this.color);
    },
    reset() {
      this.color = null;
      this.text = null;
      this.emoji = null;
      this.$refs.emojiPicker.backgroundColor = null;
    },
  },
}
</script>