var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDialogOpen)?_c('VDialog',{attrs:{"scrollable":"","content-class":`${_vm.defaultContentClass} ${_vm.customClass}`,"persistent":_vm.persistent || _vm.$vuetify.breakpoint.smAndDown,"value":_vm.isDialogOpen,"width":_vm.width,"max-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.maxWidth,"fullscreen":_vm.fullscreen || _vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":function($event){!_vm.persistent ? _vm.$emit('toggle:dialog') : null}}},[_c('VCard',{class:_vm.allCardClasses,style:({
      minHeight: _vm.minHeight
    })},[(_vm.title.length || _vm.dismissable)?_c('VCardTitle',{staticClass:"dialog-card-title"},[_c('VRow',{attrs:{"dense":"","align-center":"","color":"transparent"}},[_vm._t("title",function(){return [_c('span',{staticClass:"text-truncate text-no-wrap"},[_vm._v(_vm._s(_vm.title))])]}),_c('VSpacer'),_vm._t("actions",function(){return [(_vm.dismissable)?_c('VBtn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('toggle:dialog')}}},[_c('VIcon',{style:({
                color: _vm.closeIconColor,
              })},[_vm._v(" close ")])],1):_vm._e()]})],2)],1):_vm._e(),_c('VCardText',{class:_vm.allCardTextClasses},[_vm._t("default",null,{"uid":_vm.uid})],2),(_vm.hasAction)?_c('VCardActions',{style:({
        ..._vm.actionStyle,
        'align-items': 'flex-end',
      })},[(_vm.enableCancelButton)?_c('VCol',{attrs:{"align-start":""}},[_vm._t("footer-left",function(){return [_c('VBtn',{attrs:{"text":"","disabled":_vm.isLoading || _vm.disabled},nativeOn:{"click":function($event){return _vm.$emit('toggle:dialog')}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")])]})],2):_vm._e(),_c('VCol',{attrs:{"grow":"","align-center":""}},[_vm._t("footer-center")],2),(_vm.enableSaveButton)?_c('VCol',{attrs:{"shrink":"","align-end":""}},[_vm._t("footer-right",function(){return [_c('VBtn',{staticClass:"wethrive",attrs:{"disabled":_vm.isLoading || _vm.disabled,"loading":_vm.isLoading},on:{"click":_vm.handleAction}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])]})],2):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }